<template>
  <swiper
      :modules="modules"
      :slides-per-view="1"
      :space-between="60"
      navigation
      :pagination="{ clickable: true }"
      loop
  >
    <swiper-slide class="slide" v-for="number in 8" :key="number">
      <img :src="`https://annazavadskaya.ru/slider/${number}.webp`" class="image" :alt="`review ${number}`">
    </swiper-slide>

  </swiper>
</template>

<script>
// import Swiper core and required modules
import {A11y, Navigation, Pagination, Scrollbar} from 'swiper';

// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from 'swiper/vue';

// Import Swiper styles
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/scrollbar';

export default {
  name: "ReviewSlider",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
}
</script>

<style lang="scss" scoped>
.image {
  @media screen and (max-width: 480px) {
    width: 70vw;
  }

  object-fit: cover;
  width: 25vw;
  user-select: none;
}

.slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>