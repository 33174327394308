<template>
<body>
  <header>
    <h1>ВАШИ РЕЗУЛЬТАТЫ:</h1>
  </header>
  <div class="results">
    <div class="results-left">
      <div class="diagram">
        <img
            src="../assets/images/instructions-diagram.svg"
            alt="diagram"
            class="diagram-circle"
        />
        <img
            src="../assets/images/diagram/nevin.svg"
            alt="diagram"
            class="diagram-circle-nevin"
            :style="{ zIndex: diagram.nevin.index - 1 }"
        />
        <img
            src="../assets/images/diagram/isk.svg"
            alt="diagram"
            class="diagram-circle-isk"
            :style="{ zIndex: diagram.isk.index - 1 }"
        />
        <img
            src="../assets/images/diagram/intel.svg"
            alt="diagram"
            class="diagram-circle-intel"
            :style="{ zIndex: diagram.mudr.index - 1 }"
        />
        <img
            src="../assets/images/diagram/hero.svg"
            alt="diagram"
            class="diagram-circle-hero"
            :style="{ zIndex: diagram.hero.index - 1 }"
        />
        <img
            src="../assets/images/diagram/izmen.svg"
            alt="diagram"
            class="diagram-circle-izmen"
            :style="{ zIndex: diagram.bunt.index - 1 }"
        />
        <img
            src="../assets/images/diagram/duh.svg"
            alt="diagram"
            class="diagram-circle-duh"
            :style="{ zIndex: diagram.mag.index - 1 }"
        />
        <img
            src="../assets/images/diagram/zabot.svg"
            alt="diagram"
            class="diagram-circle-zabot"
            :style="{ zIndex: diagram.zabot.index - 1 }"
        />
        <img
            src="../assets/images/diagram/tvor.svg"
            alt="diagram"
            class="diagram-circle-tvor"
            :style="{ zIndex: diagram.tvor.index - 1 }"
        />
        <img
            src="../assets/images/diagram/prav.svg"
            alt="diagram"
            class="diagram-circle-prav"
            :style="{ zIndex: diagram.prav.index - 1 }"
        />
        <img
            src="../assets/images/diagram/mal.svg"
            alt="diagram"
            class="diagram-circle-mal"
            :style="{ zIndex: diagram.mal.index - 1 }"
        />
        <img
            src="../assets/images/diagram/love.svg"
            alt="diagram"
            class="diagram-circle-love"
            :style="{ zIndex: diagram.love.index - 1 }"
        />
        <img
            src="../assets/images/diagram/jester.svg"
            alt="diagram"
            class="diagram-circle-jester"
            :style="{ zIndex: diagram.jester.index - 1 }"
        />
        <div
            class="diagram-circle-pouring diagram-circle-nevin-block"
            :style="{
              zIndex: diagram.nevin.index,
              boxShadow: '0 0 0 ' + diagram.nevin.width + 'px rgba(40, 49, 82, 1) inset'

            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-isk-block"
            :style="{
              zIndex: diagram.isk.index,
              boxShadow: '0 0 0 ' + diagram.isk.width + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-intel-block"
            :style="{
              zIndex: diagram.mudr.index,
              boxShadow: '0 0 0 ' + diagram.mudr.width + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-hero-block"
            :style="{
              zIndex: diagram.hero.index,
              boxShadow: '0 0 0 ' + diagram.hero.width + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-izmen-block"
            :style="{
              zIndex: diagram.bunt.index,
              boxShadow: '0 0 0 ' + diagram.bunt.width + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-duh-block"
            :style="{
              zIndex: diagram.mag.index,
              boxShadow: '0 0 0 ' + diagram.mag.width + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-zabot-block"
            :style="{
              zIndex: diagram.zabot.index,
              boxShadow: '0 0 0 ' + diagram.zabot.width + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-tvor-block"
            :style="{
              zIndex: diagram.tvor.index,
              boxShadow: '0 0 0 ' + diagram.tvor.width + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-prav-block"
            :style="{
              zIndex: diagram.prav.index,
              boxShadow: '0 0 0 ' + diagram.prav.width + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-mal-block"
            :style="{
              zIndex: diagram.mal.index,
              boxShadow: '0 0 0 ' + diagram.mal.width + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-love-block"
            :style="{
              zIndex: diagram.love.index,
              boxShadow: '0 0 0 ' + diagram.love.width + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-jester-block"
            :style="{
              zIndex: diagram.jester.index,
              boxShadow: '0 0 0 ' + diagram.jester.width + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div class="diagram-circle-numbers">
          <p>{{ archetypes.nevin }}</p>
          <p>{{ archetypes.isk }}</p>
          <p>{{ archetypes.mudr }}</p>
          <p>{{ archetypes.hero }}</p>
          <p>{{ archetypes.bunt }}</p>
          <p>{{ archetypes.mag }}</p>
          <p>{{ archetypes.zabot }}</p>
          <p>{{ archetypes.tvor }}</p>
          <p>{{ archetypes.prav }}</p>
          <p>{{ archetypes.mal }}</p>
          <p>{{ archetypes.love }}</p>
          <p>{{ archetypes.jester }}</p>
        </div>
      </div>
      <div class="main-mobile-diagram" style="display: none;">
        <img src="../assets/images/instructions-diagram.svg" alt="diagram" class="main-mobile-diagram-circle"/>
        <img
            src="../assets/images/diagram/nevin.svg"
            alt="diagram"
            class="diagram-circle-nevin"
            :style="{ zIndex: diagram.nevin.index - 1 }"
        />
        <img
            src="../assets/images/diagram/isk.svg"
            alt="diagram"
            class="diagram-circle-isk"
            :style="{ zIndex: diagram.isk.index - 1 }"
        />
        <img
            src="../assets/images/diagram/intel.svg"
            alt="diagram"
            class="diagram-circle-intel"
            :style="{ zIndex: diagram.mudr.index - 1 }"
        />
        <img
            src="../assets/images/diagram/hero.svg"
            alt="diagram"
            class="diagram-circle-hero"
            :style="{ zIndex: diagram.hero.index - 1 }"
        />
        <img
            src="../assets/images/diagram/izmen.svg"
            alt="diagram"
            class="diagram-circle-izmen"
            :style="{ zIndex: diagram.bunt.index - 1 }"
        />
        <img
            src="../assets/images/diagram/duh.svg"
            alt="diagram"
            class="diagram-circle-duh"
            :style="{ zIndex: diagram.mag.index - 1 }"
        />
        <img
            src="../assets/images/diagram/zabot.svg"
            alt="diagram"
            class="diagram-circle-zabot"
            :style="{ zIndex: diagram.zabot.index - 1 }"
        />
        <img
            src="../assets/images/diagram/tvor.svg"
            alt="diagram"
            class="diagram-circle-tvor"
            :style="{ zIndex: diagram.tvor.index - 1 }"
        />
        <img
            src="../assets/images/diagram/prav.svg"
            alt="diagram"
            class="diagram-circle-prav"
            :style="{ zIndex: diagram.prav.index - 1 }"
        />
        <img
            src="../assets/images/diagram/mal.svg"
            alt="diagram"
            class="diagram-circle-mal"
            :style="{ zIndex: diagram.mal.index - 1 }"
        />
        <img
            src="../assets/images/diagram/love.svg"
            alt="diagram"
            class="diagram-circle-love"
            :style="{ zIndex: diagram.love.index - 1 }"
        />
        <img
            src="../assets/images/diagram/jester.svg"
            alt="diagram"
            class="diagram-circle-jester"
            :style="{ zIndex: diagram.jester.index - 1 }"
        />
        <div
            class="diagram-circle-pouring diagram-circle-nevin-block"
            :style="{
              zIndex: diagram.nevin.index,
              boxShadow: '0 0 0 ' + diagram.nevin.mobileWidth + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-isk-block"
            :style="{
              zIndex: diagram.isk.index,
              boxShadow: '0 0 0 ' + diagram.isk.mobileWidth + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-intel-block"
            :style="{
              zIndex: diagram.mudr.index,
              boxShadow: '0 0 0 ' + diagram.mudr.mobileWidth + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-hero-block"
            :style="{
              zIndex: diagram.hero.index,
              boxShadow: '0 0 0 ' + diagram.hero.mobileWidth + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-izmen-block"
            :style="{
              zIndex: diagram.bunt.index,
              boxShadow: '0 0 0 ' + diagram.bunt.mobileWidth + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-duh-block"
            :style="{
              zIndex: diagram.mag.index,
              boxShadow: '0 0 0 ' + diagram.mag.mobileWidth + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-zabot-block"
            :style="{
              zIndex: diagram.zabot.index,
              boxShadow: '0 0 0 ' + diagram.zabot.mobileWidth + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-tvor-block"
            :style="{
              zIndex: diagram.tvor.index,
              boxShadow: '0 0 0 ' + diagram.tvor.mobileWidth + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-prav-block"
            :style="{
              zIndex: diagram.prav.index,
              boxShadow: '0 0 0 ' + diagram.prav.mobileWidth + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-mal-block"
            :style="{
              zIndex: diagram.mal.index,
              boxShadow: '0 0 0 ' + diagram.mal.mobileWidth + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-love-block"
            :style="{
              zIndex: diagram.love.index,
              boxShadow: '0 0 0 ' + diagram.love.mobileWidth + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-jester-block"
            :style="{
              zIndex: diagram.jester.index,
              boxShadow: '0 0 0 ' + diagram.jester.mobileWidth + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div class="diagram-circle-numbers">
          <p>{{ archetypes.nevin }}</p>
          <p>{{ archetypes.isk }}</p>
          <p>{{ archetypes.mudr }}</p>
          <p>{{ archetypes.hero }}</p>
          <p>{{ archetypes.bunt }}</p>
          <p>{{ archetypes.mag }}</p>
          <p>{{ archetypes.zabot }}</p>
          <p>{{ archetypes.tvor }}</p>
          <p>{{ archetypes.prav }}</p>
          <p>{{ archetypes.mal }}</p>
          <p>{{ archetypes.love }}</p>
          <p>{{ archetypes.jester }}</p>
        </div>
      </div>
    </div>
    <div class="results-right">
      <div class="results-right-banner">
        <img src="../assets/images/results-anna-with-book.webp" alt="anna">
        <div class="results-right-banner-block">
          <h3>
            ПОЛУЧИТЕ СТАРТОВЫЕ МАТЕРИАЛЫ В ЧАТ-БОТЕ
          </h3>
          <hr class="hr">
          <p>
            ✔️ <b>Бесплатный мини-курс</b> «Архетипы в личном брендинге»  <br><br>
            ✔️ <b>Pdf-план</b> по построению объемного личного бренда по авторской методологии 12D <br><br>
          </p>
          <a href="https://mnlp.cc/mini?domain=zavadskayapro&id=2" target="_blank" class="button">ЗАПУСТИТЬ ТЕЛЕГРАМ-БОТ</a>
<!--          <button class="button" @click="isVisiblePopup = true">-->
<!--            РАСШИРЕННЫЙ РЕЗУЛЬТАТ-->
<!--          </button>-->
        </div>
      </div>
    </div>
      <h2 class="center-h2-mobile" style="display: none;">Ваши сильные архетипы:</h2>
      <div class="bottom-results-mobile" style="display: none;">
        <div class="bottom-results-mobile-archetypes">
          <div class="bottom-results-mobile-archetypes-info">
            <div class="bottom-results-mobile-archetypes-info-block" v-for="(value, key) in mobileBlocks" :key="key">
              <p>{{ archetypes[key] }}</p>
              <hr class="hr">
              <div>
                <div class="bottom-results-mobile-archetypes-info-block-div">
                  <img
                      :src="'https://annazavadskaya.ru/icons/mobile/' + value.image"
                      :alt="key"
                  >
                </div>
                <p class="bottom-results-mobile-archetypes-info-block-p" v-html="value.name"></p>
              </div>
              <Transition name="mobile-block">
                <div
                    class="bottom-results-mobile-archetypes-describe"
                >
                  <div :style="{ right: '268px' }"></div>
                  <p>
                    {{ value.text }}
                    <a :href="value.link" target="_blank"><b>Перейти к описанию >>></b></a>
                  </p>
                </div>
              </Transition>
            </div>
          </div>
        </div>
      </div>
      <div class="results-right-mobile-video" style="display: none;">
        <div class="results-right-mobile-video-block">
          <h3>
            ПОЛУЧИТЕ СТАРТОВЫЕ МАТЕРИАЛЫ В ЧАТ-БОТЕ
          </h3>
          <hr class="hr">
          <p>
            ✔️ <b>Бесплатный мини-курс</b> «Архетипы в личном брендинге» <br><br>
            ✔️ <b>Pdf-план</b> по построению объемного личного бренда по авторской методологии 12D
          </p>
          <a href="https://mnlp.cc/mini?domain=zavadskayapro&id=2" target="_blank" class="button">ЗАПУСТИТЬ ТЕЛЕГРАМ-БОТ</a>
<!--          <button class="button" @click="isVisiblePopup = true">-->
<!--            РАСШИРЕННЫЙ РЕЗУЛЬТАТ-->
<!--          </button>-->
        </div>
      </div>
  </div>
  <h2 class="center-h2">Ваши сильные архетипы:</h2>
  <div class="archetypes">
    <div class="archetypes-block" v-for="(value, key) in enabledBlocks" :key="key">
      <div class="archetypes-block-image">
        <img :src="'https://annazavadskaya.ru/icons/' + value.image" :alt="key">
      </div>
      <hr class="hr">
      <p class="archetypes-block-percent">{{ value.points }}</p>
      <h3 v-html="value.name + ':'"></h3>
      <p class="archetypes-block-p">
        {{ value.text }}
        <a :href="value.link" target="_blank"><b>Перейти к описанию >>></b></a>
      </p>
    </div>
  </div>
  <div class="book">
    <hr class="hr">
    <div class="book-light"></div>
    <img src="../assets/images/results-anna-bottom.webp" alt="Анна Завадская" class="book-img">
    <div class="book-set">
      <div class="book-set-block">
        <p>
          <span>Получите стартовые материалы</span> по архетипам и личному бренду в телеграм
        </p>
        <a href="https://mnlp.cc/mini?domain=zavadskayapro&id=2" target="_blank" class="button">ПЕРЕЙТИ В БОТ</a>
      </div>
    </div>
    <hr class="hr book-hr">
  </div>
  <div class="book-mobile" style="display: none;">
    <p>
      <span>Получите стартовые материалы</span> по архетипам и личному бренду в телеграм
    </p>
    <img src="../assets/images/mobile/results-arrow-bottom.svg" alt="arrow">
    <a href="https://mnlp.cc/mini?domain=zavadskayapro&id=2" target="_blank" class="button">ПЕРЕЙТИ В БОТ</a>
<!--    <button class="button" @click="isVisiblePopup = true">ОПЛАТИТЬ</button>-->

    <div class="book-mobile-anna">
      <div class="book-mobile-anna-light"></div>
      <img src="../assets/images/mobile/results-anna.webp" alt="anna" class="book-mobile-anna-img">
    </div>
  </div>
  <footer>
    <div class="footer">
      <div class="footer-text">
        © 2019 zavadskaya.online <br>
        Авторские права защищены <br>
        Копирование запрещено
      </div>
      <div class="footer-links">
        <a href="https://t.me/zavadskaya12D" target="_blank">
          <img src="../assets/images/results-tg.svg" alt="Telegram" class="footer-links">
        </a>
      </div>
    </div>
  </footer>

  <div class="mobile-links" style="display: none;">
    <div>
      <a href="https://t.me/zavadskaya12D" target="_blank">
        <img src="../assets/images/results-tg.svg" alt="Telegram">
      </a>
    </div>
  </div>
  <transition name="popup">
    <popup v-if="isVisiblePopup" @closePopup="closePopup" />
  </transition>
</body>
</template>

<script>
import router from "@/router";
import popup from "@/components/PopupPayment";

export default {
  name: "ResultsCutView",
  components: { popup },
  data() {
    return {
      mobileBlocks: {},
      blocks: {
        bunt: {
          name: "АРХЕТИП БУНТАРЬ",
          points: 0,
          text: 'Реформирующий лидер - активно реализует свое видение мира, разрушая на пути все преграды и стереотипы.',
          link: 'https://zavadskaya.online/arxetip-buntar-izgoj',
          image: "results-bunt.svg",
          isEnabled: false
        },
        hero: {
          name: "АРХЕТИП ГЕРОЙ",
          points: 0,
          text: 'Совершенствующий лидер - становится мотивирующим примером в создании успешных и прорывных проектов.',
          link: 'https://zavadskaya.online/arxetip-geroj-voin',
          image: "results-hero.svg",
          isEnabled: false
        },
        isk: {
          name: "АРХЕТИП ИСКАТЕЛЬ",
          points: 0,
          text: 'Исследующий лидер - знает в какую сторону движутся тенденции и становится первопроходцем в любой сфере.',
          link: 'https://zavadskaya.online/arxetip-iskatel-strannik',
          image: "results-isk.svg",
          isEnabled: false
        },
        jester: {
          name: "АРХЕТИП ШУТ",
          points: 0,
          text: 'Вовлекающий лидер - умело вовлекает в происходящее, создает яркие впечатления и игровые формы взаимодействия.',
          link: 'https://zavadskaya.online/arxetip-shut',
          image: "results-shut.svg",
          isEnabled: false
        },
        love: {
          name: "АРХЕТИП ЛЮБОВНИК",
          points: 0,
          text: 'Влюбляющий лидер - выстраивает душевную и доверительную коммуникацию.\n' +
              'Эффектно презентует проекты и идеи.',
          link: 'https://zavadskaya.online/arxetip-lyubovnik-estet',
          image: "results-love.svg",
          isEnabled: false
        },
        mag: {
          name: "АРХЕТИП МАГ",
          points: 0,
          text: 'Трансформирующий лидер - преображает всё, к чему прикасается и всех, кто попадает в поле его влияния.',
          link: 'https://zavadskaya.online/arxetip-mag-volshebnik',
          image: "results-mag.svg",
          isEnabled: false
        },
        mal: {
          name: "АРХЕТИП СЛАВНЫЙ МАЛЫЙ",
          points: 0,
          text: 'Объединяющий лидер - эффективно объединяет людей, создает взаимовыгодные отношения и слаженные команды.',
          link: 'https://zavadskaya.online/arxetip-slavnyj-malyj',
          image: "results-mal.svg",
          isEnabled: false
        },
        mudr: {
          name: "АРХЕТИП МУДРЕЦ",
          points: 0,
          text: 'Структурирующий лидер - несет экспертные знания в массы.\n' +
              'Помогает найти ответы и обрести истинную мудрость.',
          link: 'https://zavadskaya.online/arxetip-mudrec-filosof',
          image: "results-mudr.svg",
          isEnabled: false
        },
        nevin: {
          name: "АРХЕТИП НЕВИННЫЙ",
          points: 0,
          text: 'Вдохновляющий лидер - жизнерадостный и воодушевляющий мечтатель.\n' +
              'Вносит легкость и вселяет веру в лучшее.',
          link: 'https://zavadskaya.online/arxetip-nevinnyj-prostodushnyj',
          image: "results-nevin.svg",
          isEnabled: false
        },
        prav: {
          name: "АРХЕТИП ПРАВИТЕЛЬ",
          points: 0,
          text: 'Масштабирующий лидер - выстраивает стратегию на перспективу, успешно управляет людьми и планомерно растет.',
          link: 'https://zavadskaya.online/arxetip-pravitel',
          image: "results-prav.svg",
          isEnabled: false
        },
        tvor: {
          name: "АРХЕТИП ТВОРЕЦ",
          points: 0,
          text: 'Генерирующий лидер - увлечен идеями и их уникальной реализацией.\n' +
              'Создает новое и дорабатывает уже созданное.',
          link: 'https://zavadskaya.online/arxetip-tvorec',
          image: "results-tvor.svg",
          isEnabled: false
        },
        zabot: {
          name: "АРХЕТИП ЗАБОТЛИВЫЙ",
          points: 0,
          text: 'Поддерживающий лидер - сфокусирован на создании комфортной и безопасной среды для гармоничного роста.',
          link: 'https://zavadskaya.online/arxetip-zabotlivyj-xranitel',
          image: "results-zabot.svg",
          isEnabled: false
        },
      },
      currentTriangle: 0,
      currentDisplay: {
        one: false,
      },
      mobileTriangle: [264, 148, 34],
      mobileState: -1,
      session: this.$route.params.session,
      archetypes: {
        nevin: 0,
        mal: 0,
        hero: 0,
        zabot: 0,
        isk: 0,
        bunt: 0,
        love: 0,
        tvor: 0,
        prav: 0,
        mag: 0,
        mudr: 0,
        jester: 0
      },
      diagram: {
        nevin: {
          index: 1,
          width: 1,
          mobileWidth: 1
        },
        mal: {
          index: 1,
          width: 1,
          mobileWidth: 1
        },
        hero: {
          index: 1,
          width: 1,
          mobileWidth: 1
        },
        zabot: {
          index: 1,
          width: 1,
          mobileWidth: 1
        },
        isk: {
          index: 1,
          width: 1,
          mobileWidth: 1
        },
        bunt: {
          index: 1,
          width: 1,
          mobileWidth: 1
        },
        love: {
          index: 1,
          width: 1,
          mobileWidth: 1
        },
        tvor: {
          index: 1,
          width: 1,
          mobileWidth: 1
        },
        prav: {
          index: 1,
          width: 1,
          mobileWidth: 1
        },
        mag: {
          index: 1,
          width: 1,
          mobileWidth: 1
        },
        mudr: {
          index: 1,
          width: 1,
          mobileWidth: 1
        },
        jester: {
          index: 1,
          width: 1,
          mobileWidth: 1
        },
        currentIndex: 2,
        circle: [135, 120, 109, 98, 86, 74, 62, 51, 39, 28, 0],
        circleMobile: [73, 64, 57, 50, 43, 36, 29, 22, 15, 8, 0],
      },
      isVisiblePopup: false,
    }
  },
  methods: {
    getMobileBlocks() {
      const enabledItems = Object.entries(this.blocks)
          .filter(([, item]) => item.isEnabled)
          .sort(([, itemA], [, itemB]) => itemB.points - itemA.points) // Сортировка по количеству баллов
          .slice(0, 3);

      // Удаление четвертого элемента, если он существует
      if (enabledItems.length >= 4) {
        enabledItems.splice(3, 1);
      }

      // Преобразовываем обратно в объект
      return Object.fromEntries(enabledItems);
    },
    closePopup() {
      this.isVisiblePopup = false;
    },
  },
  computed: {
    enabledBlocks() {
      return Object.entries(this.blocks)
          .filter(([, block]) => block.isEnabled)
          .sort(([, blockA], [, blockB]) => blockB.points - blockA.points) // Сортировка по количеству баллов
          .reduce((obj, [key, block]) => {
            obj[key] = block;
            return obj;
          }, {});
    },
  },

  async beforeMount() {
    await getResult(this);
    if (this.$route.params.payment) {
      this.isVisiblePopup = true;
    }
  },
}

async function getResult(app) {
  app.axios.post("https://dev.annazavadskaya.ru:3000/api/results/", { id: app.session })
  .then(async (res) => {
    // app.archetypes = {bunt: 55, hero: 54, isk: 53, jester: 52, love: 0, mag: 0, mal: 0, mudr: 0, nevin: 0, prav: 0, tvor: 0, zabot: 0};
    app.archetypes = {...res.data};

    let conclusion = await findTop(app.archetypes, 4);

    conclusion.forEach((value) => {
      app.blocks[value].isEnabled = true;
      app.blocks[value].points = app.archetypes[value];
    });

    app.mobileBlocks = await app.getMobileBlocks();

    if (app.archetypes.nevin === 0 && app.archetypes.mal === 0 && app.archetypes.hero === 0 && app.archetypes.zabot === 0) {
      return router.push({name: 'home'});
    }

    calculateDiagram(app);
  });
}

function findTop(obj, topVal) {
  return Object.keys(obj)
      .sort((a, b) => obj[b] - obj[a])
      .slice(0, topVal);
}

// подсчёты

function calculateDiagram(app) {
  let nevin = Math.floor(app.archetypes.nevin / 10);
  let mal = Math.floor(app.archetypes.mal / 10);
  let hero = Math.floor(app.archetypes.hero / 10);
  let zabot = Math.floor(app.archetypes.zabot / 10);
  let isk = Math.floor(app.archetypes.isk / 10);
  let bunt = Math.floor(app.archetypes.bunt / 10);
  let love = Math.floor(app.archetypes.love / 10);
  let tvor = Math.floor(app.archetypes.tvor / 10);
  let prav = Math.floor(app.archetypes.prav / 10);
  let mag = Math.floor(app.archetypes.mag / 10);
  let mudr = Math.floor(app.archetypes.mudr / 10);
  let jester = Math.floor(app.archetypes.jester / 10);

  let array = [nevin, mal, hero, zabot, isk, bunt, love, prav, tvor, mag, mudr, jester];
  let arrayText = ["nevin", "mal", "hero", "zabot", "isk", "bunt", "love", "prav", "tvor", "mag", "mudr", "jester"];

  for (let i = 0; i < 11; i++) {
    let res = Math.min(...array);
    let resArray = [];
    if (res !== 15) {
      array.forEach((el, index, arr) => {
        if (el === res) {
          resArray.push(index);
          arr[index] = 15;
        }
      });
    }

    if (resArray.length !== 0) {
      if (resArray.length > 1) {
        resArray.forEach((el) => {
          app.diagram[arrayText[el]].index = app.diagram.currentIndex + 2;
        });
        app.diagram.currentIndex += 2;
      } else {
        app.diagram[arrayText[resArray[0]]].index = app.diagram.currentIndex;
      }
    }

    app.diagram.currentIndex += 2;
  }

  app.diagram.nevin.width = app.diagram.circle[nevin];
  app.diagram.mal.width = app.diagram.circle[mal];
  app.diagram.hero.width = app.diagram.circle[hero];
  app.diagram.zabot.width = app.diagram.circle[zabot];
  app.diagram.isk.width = app.diagram.circle[isk];
  app.diagram.bunt.width = app.diagram.circle[bunt];
  app.diagram.love.width = app.diagram.circle[love];
  app.diagram.tvor.width = app.diagram.circle[tvor];
  app.diagram.prav.width = app.diagram.circle[prav];
  app.diagram.mag.width = app.diagram.circle[mag];
  app.diagram.mudr.width = app.diagram.circle[mudr];
  app.diagram.jester.width = app.diagram.circle[jester];

  app.diagram.nevin.mobileWidth = app.diagram.circleMobile[nevin];
  app.diagram.mal.mobileWidth = app.diagram.circleMobile[mal];
  app.diagram.hero.mobileWidth = app.diagram.circleMobile[hero];
  app.diagram.zabot.mobileWidth = app.diagram.circleMobile[zabot];
  app.diagram.isk.mobileWidth = app.diagram.circleMobile[isk];
  app.diagram.bunt.mobileWidth = app.diagram.circleMobile[bunt];
  app.diagram.love.mobileWidth = app.diagram.circleMobile[love];
  app.diagram.tvor.mobileWidth = app.diagram.circleMobile[tvor];
  app.diagram.prav.mobileWidth = app.diagram.circleMobile[prav];
  app.diagram.mag.mobileWidth = app.diagram.circleMobile[mag];
  app.diagram.mudr.mobileWidth = app.diagram.circleMobile[mudr];
  app.diagram.jester.mobileWidth = app.diagram.circleMobile[jester];
}

</script>

<style lang="scss" scoped>
@import "/src/assets/css/resultsCut.scss";
</style>