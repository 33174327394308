<template>
<body>
  <h2>Тест на Архетипы 12D</h2>
  <img src="../assets/images/test-grid-left.svg" alt="grid" class="grid-left">
  <img src="../assets/images/test-grid-right.svg" alt="grid" class="grid-right">
  <img src="../assets/images/mobile/test-grid.svg" alt="grid" class="grid-mobile" style="display: none;">
  <div class="quantity">
    <img src="../assets/images/arrow-left.svg" alt="arrow-left">
    <p class="quantity-p">
      {{ questions.currentCount }}<span class="quantity-span">/{{ questions.quantity }}</span>
    </p>
    <img src="../assets/images/arrow-right.svg" alt="arrow-right">
  </div>
  <p class="question">
    {{
      questions.currentQuestion
    }}
  </p>
  <div class="text-mobile" style="display: none;">
    <p>Вообще не подходит</p>
    <p>100% про меня</p>
  </div>
  <div class="slider-mobile" style="display: none;">
    <input type="range" min="0" max="10" step="1" v-model="form.value" class="slider-mobile-input">
    <div class="slider-mobile-rectangle">
      <img src="../assets/images/mobile/rectangle-big.svg" alt="rectangle" class="slider-mobile-rectangle-padding">
      <img src="../assets/images/mobile/rectangle-small.svg" alt="rectangle" class="slider-mobile-rectangle-padding">
      <img src="../assets/images/mobile/rectangle-small.svg" alt="rectangle" class="slider-mobile-rectangle-padding">
      <img src="../assets/images/mobile/rectangle-small.svg" alt="rectangle" class="slider-mobile-rectangle-padding">
      <img src="../assets/images/mobile/rectangle-small.svg" alt="rectangle" class="slider-mobile-rectangle-padding">
      <img src="../assets/images/mobile/rectangle-big.svg" alt="rectangle" class="slider-mobile-rectangle-padding">
      <img src="../assets/images/mobile/rectangle-small.svg" alt="rectangle" class="slider-mobile-rectangle-padding">
      <img src="../assets/images/mobile/rectangle-small.svg" alt="rectangle" class="slider-mobile-rectangle-padding">
      <img src="../assets/images/mobile/rectangle-small.svg" alt="rectangle" class="slider-mobile-rectangle-padding">
      <img src="../assets/images/mobile/rectangle-small.svg" alt="rectangle" class="slider-mobile-rectangle-padding">
      <img src="../assets/images/mobile/rectangle-big.svg" alt="rectangle" class="slider-mobile-rectangle-padding">
    </div>
    <div class="slider-mobile-rectangle slider-mobile-number">
      <span @click="form.value = 0">0</span>
      <span @click="form.value = 1">1</span>
      <span @click="form.value = 2">2</span>
      <span @click="form.value = 3">3</span>
      <span @click="form.value = 4">4</span>
      <span @click="form.value = 5">5</span>
      <span @click="form.value = 6">6</span>
      <span @click="form.value = 7">7</span>
      <span @click="form.value = 8">8</span>
      <span @click="form.value = 9">9</span>
      <span @click="form.value = 10">10</span>
    </div>
  </div>
  <div class="answer">
    <p>Вообще не подходит</p>
    <input type="range" min="0" max="10" step="1" v-model="form.value" class="answer-input">
    <p>100% про меня</p>
    <div class="slider">
      <img src="../assets/images/Rectangle-big.svg" alt="rectangle" class="slider-padding">
      <img src="../assets/images/Rectangle-small.svg" alt="rectangle" class="slider-padding">
      <img src="../assets/images/Rectangle-small.svg" alt="rectangle" class="slider-padding">
      <img src="../assets/images/Rectangle-small.svg" alt="rectangle" class="slider-padding">
      <img src="../assets/images/Rectangle-small.svg" alt="rectangle" class="slider-padding">
      <img src="../assets/images/Rectangle-big.svg" alt="rectangle" class="slider-padding">
      <img src="../assets/images/Rectangle-small.svg" alt="rectangle" class="slider-padding">
      <img src="../assets/images/Rectangle-small.svg" alt="rectangle" class="slider-padding">
      <img src="../assets/images/Rectangle-small.svg" alt="rectangle" class="slider-padding">
      <img src="../assets/images/Rectangle-small.svg" alt="rectangle" class="slider-padding">
      <img src="../assets/images/Rectangle-big.svg" alt="rectangle" class="slider-padding">
    </div>
    <div class="slider slider-number">
      <span @click="form.value = 0">0</span>
      <span @click="form.value = 1">1</span>
      <span @click="form.value = 2">2</span>
      <span @click="form.value = 3">3</span>
      <span @click="form.value = 4">4</span>
      <span @click="form.value = 5">5</span>
      <span @click="form.value = 6">6</span>
      <span @click="form.value = 7">7</span>
      <span @click="form.value = 8">8</span>
      <span @click="form.value = 9">9</span>
      <span @click="form.value = 10">10</span>
    </div>
  </div>
  <div class="buttons">
    <button
        class="buttons-button"
        @click="previousQuestion"
        :disabled="form.isDisabled"
    >Назад</button>
    <button
        :class="[!questions.isLastQuestion ? 'buttons-button buttons-button-alert' : 'button buttons-last']"
        @click="sendAnswer"
        :disabled="form.isDisabled"
    >
      {{ !questions.isLastQuestion ? 'Ответить' : 'Узнать результат'}}
    </button>
  </div>
  <div class="note">
    Для корректной работы теста необходимо отключить VPN. <br>
    Если страница зависла, то обнови её и продолжи дальше.
  </div>
</body>
</template>

<script>
import Cookies from 'js-cookie';
import router from "@/router";

export default {
  name: "TestView",
  data() {
    return {
      form: {
        value: 5,
        isDisabled: false
      },
      questions: {
        quantity: 0,
        currentCount: 0,
        currentType: '',
        currentQuestion: '',
        isLastQuestion: false
      },
      session: Cookies.get("session")
    }
  },
  methods: {
    previousQuestion() {
      if (this.questions.currentCount === 1) {
        return router.push({name: 'instructions'});
      }

      this.form.isDisabled = true;
      this.axios.post('https://dev.annazavadskaya.ru:3000/api/question/get/previous', {
        id: this.session,
        questionId: this.questions.currentCount
      })
      .then((res) => {
        this.questions.currentCount = res.data.number;
        this.questions.currentType = res.data.type;
        this.questions.currentQuestion = res.data.text;
        this.form.isDisabled = false;
        this.form.value = 5;
      });
    },

    sendAnswer() {
      this.form.isDisabled = true;
      this.axios.post('https://dev.annazavadskaya.ru:3000/api/question/send',
          {
            id: this.session,
            questionId: this.questions.currentCount,
            type: this.questions.currentType,
            answer: this.form.value
          })
        .then(() => {
          if (this.questions.currentCount === this.questions.quantity) {
            return router.push({name: "email"});
          } else {
            this.axios.post('https://dev.annazavadskaya.ru:3000/api/question/get/', {id: this.session})
                .then((res) => {
                  this.questions.currentCount = res.data.number;
                  this.questions.currentType = res.data.type;
                  this.questions.currentQuestion = res.data.text;
                  this.form.isDisabled = false;
                  this.form.value = 5;

                  if (this.questions.currentCount === this.questions.quantity) {
                    this.questions.isLastQuestion = true;
                  }
                });
         }
        });
    }
  },
  beforeMount() {
    if (this.session === undefined) {
      return router.push({name: 'home'});
    }

    this.form.isDisabled = true;

    getCurrentQuestion(this, this.session);
  }
}

function getCurrentQuestion(app, session) {
  app.axios.get('https://dev.annazavadskaya.ru:3000/api/question/get/quantity').then((res) => {
    app.questions.quantity = res.data;

    app.axios.post('https://dev.annazavadskaya.ru:3000/api/question/get/', { id: session })
        .then((res) => {
          app.questions.currentCount = res.data.number;
          app.questions.currentType = res.data.type;
          app.questions.currentQuestion = res.data.text;
          app.form.isDisabled = false;

          if (app.questions.currentCount === app.questions.quantity) {
            app.questions.isLastQuestion = true;
          } else if (app.questions.currentCount > app.questions.quantity) {
            return router.push({name: "email"});
          }
        });
  });
}

</script>

<style lang="scss" scoped>
@import "/src/assets/css/test.scss";
</style>