import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import InstructionsView from "@/views/InstructionsView";
import TestView from "@/views/TestView";
import ResultsCutView from "@/views/ResultsCutView";
import ResultsView from "@/views/ResultsView";
import ResultsActivateView from "@/views/ResultsActivateView";
import EmailView from "@/views/EmailView";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/instructions",
    name: "instructions",
    component: InstructionsView,
  },
  {
    path: "/test",
    name: "test",
    component: TestView,
  },
  {
    path: "/test/email",
    name: "email",
    component: EmailView
  },
  {
    path: "/results/:session",
    name: "results-cut",
    component: ResultsCutView,
  },
  {
    path: "/results/:session/:payment?",
    name: "results-cut",
    component: ResultsCutView,
  },
  {
    path: "/results/activate/:link",
    name: "results-activate",
    component: ResultsActivateView,
  },
  {
    path: "/results/:session/show",
    name: "results",
    component: ResultsView,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
