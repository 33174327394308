<template>
<body>

</body>
</template>

<script>
import router from "@/router";


export default {
  name: "ResultsActivateView",
  data() {
    return {
      link: this.$route.params.link
    }
  },
  beforeMount() {
    this.axios.post("https://dev.annazavadskaya.ru:3000/api/user/activate", { id: this.link })
        .then((res) => {
          if (res.data) {
            let session = res.data;
            this.axios.post('https://dev.annazavadskaya.ru:3000/api/results/extended/send', { id: session })
              .then(() => {
                return router.push({name: "results", params: {session: session}});
              });
          } else {
            return router.push({name: "home"});
          }
        });
  }
}
</script>

<style scoped>

</style>