<template>
  <div class="popup">
    <div class="popup-block">
      <div class="popup-block-header">Оплата</div>
      <hr>
      <div class="popup-block-content">
        <p>Получить подробный разбор результатов теста (99 ₽)</p>
        <p style="width: 435px; margin-top: 20px;">
          После оплаты вам откроется страница с подробными результатами теста. <br>
          Также на емейл будет отправлена копия (проверьте папки "промоакции" или "спам", если письма нет во входящих).
        </p>
        <form class="payform-tinkoff" name="payform-tinkoff" id="payform-tinkoff" ref="form" @submit.prevent="payment">
          <input class="payform-tinkoff-row" type="hidden" name="terminalkey" value="1699715388064DEMO">
          <input class="payform-tinkoff-row" type="hidden" placeholder="Номер заказа" name="order" value="77777">
          <input class="payform-tinkoff-row" type="hidden" name="frame" value="false">
          <input class="payform-tinkoff-row" type="hidden" name="language" value="ru">
          <input class="payform-tinkoff-row" type="hidden" name="receipt" value="">
          <input class="payform-tinkoff-row" type="text" placeholder="Сумма заказа" name="amount" value="99" hidden>
          <input class="payform-tinkoff-row" type="hidden" placeholder="Номер заказа" name="order" hidden>
          <input class="payform-tinkoff-row" type="text" placeholder="Описание заказа" name="description" hidden>
          <input class="payform-tinkoff-row" type="text" placeholder="ФИО плательщика" name="name" hidden>
          <input class="payform-tinkoff-row" type="tel" placeholder="Контактный телефон" name="phone">
          <input class="payform-tinkoff-row" type="email" placeholder="E-mail" name="email" required>
          <label for="agree" style="margin-top: 10px;">
            <input type="checkbox" id="agree" name="agree" required>
            Я ознакомлен(а) с условиями <a href="https://zavadskaya.online/oferta" target="_blank">оферты</a>
          </label>
          <input class="payform-tinkoff-btn button cancel" type="reset" value="Отменить" @click="hide">
          <input class="payform-tinkoff-btn button submit" type="submit" value="Оплатить">

        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopupPayment",
  data() {
    return {
      session: this.$route.params.session
    }
  },
  methods: {
    hide() {
      this.$emit('closePopup');
    },

    payment() {
      const TPF = this.$refs.form;
      const {email, phone} = TPF;

      console.log(phone.value);

      this.axios.post('https://dev.annazavadskaya.ru:3000/api/payment/send', {email: email.value, phone: phone.value, session: this.session})
          .then((res) => {
            if (res.data) {
              window.location.href = res.data;
            } else {
              console.log(res.data);
            }
          });

    }
  },
  mounted() {
    const script = document.createElement('script');
    script.src = 'https://securepay.tinkoff.ru/html/payForm/js/tinkoff_v2.js';
    script.async = true;
    document.head.appendChild(script);
  }
}
</script>

<style lang="scss" scoped>
@import "/src/assets/css/popupPayment.scss";
</style>