<template>
<body>
  <header>
    <h1>РАСШИРЕННЫЕ РЕЗУЛЬТАТЫ</h1>
  </header>
  <div class="results">
    <div class="results-left">
      <div class="diagram">
        <img
            src="../assets/images/instructions-diagram.svg"
            alt="diagram"
            class="diagram-circle"
        />
        <img
            src="../assets/images/diagram/nevin.svg"
            alt="diagram"
            class="diagram-circle-nevin"
            :style="{ zIndex: diagram.nevin.index - 1 }"
        />
        <img
            src="../assets/images/diagram/isk.svg"
            alt="diagram"
            class="diagram-circle-isk"
            :style="{ zIndex: diagram.isk.index - 1 }"
        />
        <img
            src="../assets/images/diagram/intel.svg"
            alt="diagram"
            class="diagram-circle-intel"
            :style="{ zIndex: diagram.mudr.index - 1 }"
        />
        <img
            src="../assets/images/diagram/hero.svg"
            alt="diagram"
            class="diagram-circle-hero"
            :style="{ zIndex: diagram.hero.index - 1 }"
        />
        <img
            src="../assets/images/diagram/izmen.svg"
            alt="diagram"
            class="diagram-circle-izmen"
            :style="{ zIndex: diagram.bunt.index - 1 }"
        />
        <img
            src="../assets/images/diagram/duh.svg"
            alt="diagram"
            class="diagram-circle-duh"
            :style="{ zIndex: diagram.mag.index - 1 }"
        />
        <img
            src="../assets/images/diagram/zabot.svg"
            alt="diagram"
            class="diagram-circle-zabot"
            :style="{ zIndex: diagram.zabot.index - 1 }"
        />
        <img
            src="../assets/images/diagram/tvor.svg"
            alt="diagram"
            class="diagram-circle-tvor"
            :style="{ zIndex: diagram.tvor.index - 1 }"
        />
        <img
            src="../assets/images/diagram/prav.svg"
            alt="diagram"
            class="diagram-circle-prav"
            :style="{ zIndex: diagram.prav.index - 1 }"
        />
        <img
            src="../assets/images/diagram/mal.svg"
            alt="diagram"
            class="diagram-circle-mal"
            :style="{ zIndex: diagram.mal.index - 1 }"
        />
        <img
            src="../assets/images/diagram/love.svg"
            alt="diagram"
            class="diagram-circle-love"
            :style="{ zIndex: diagram.love.index - 1 }"
        />
        <img
            src="../assets/images/diagram/jester.svg"
            alt="diagram"
            class="diagram-circle-jester"
            :style="{ zIndex: diagram.jester.index - 1 }"
        />
        <div
            class="diagram-circle-pouring diagram-circle-nevin-block"
            :style="{
              zIndex: diagram.nevin.index,
              boxShadow: '0 0 0 ' + diagram.nevin.width + 'px rgba(40, 49, 82, 1) inset'

            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-isk-block"
            :style="{
              zIndex: diagram.isk.index,
              boxShadow: '0 0 0 ' + diagram.isk.width + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-intel-block"
            :style="{
              zIndex: diagram.mudr.index,
              boxShadow: '0 0 0 ' + diagram.mudr.width + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-hero-block"
            :style="{
              zIndex: diagram.hero.index,
              boxShadow: '0 0 0 ' + diagram.hero.width + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-izmen-block"
            :style="{
              zIndex: diagram.bunt.index,
              boxShadow: '0 0 0 ' + diagram.bunt.width + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-duh-block"
            :style="{
              zIndex: diagram.mag.index,
              boxShadow: '0 0 0 ' + diagram.mag.width + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-zabot-block"
            :style="{
              zIndex: diagram.zabot.index,
              boxShadow: '0 0 0 ' + diagram.zabot.width + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-tvor-block"
            :style="{
              zIndex: diagram.tvor.index,
              boxShadow: '0 0 0 ' + diagram.tvor.width + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-prav-block"
            :style="{
              zIndex: diagram.prav.index,
              boxShadow: '0 0 0 ' + diagram.prav.width + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-mal-block"
            :style="{
              zIndex: diagram.mal.index,
              boxShadow: '0 0 0 ' + diagram.mal.width + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-love-block"
            :style="{
              zIndex: diagram.love.index,
              boxShadow: '0 0 0 ' + diagram.love.width + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-jester-block"
            :style="{
              zIndex: diagram.jester.index,
              boxShadow: '0 0 0 ' + diagram.jester.width + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div class="diagram-circle-numbers">
          <p>{{ archetypes.nevin }}</p>
          <p>{{ archetypes.isk }}</p>
          <p>{{ archetypes.mudr }}</p>
          <p>{{ archetypes.hero }}</p>
          <p>{{ archetypes.bunt }}</p>
          <p>{{ archetypes.mag }}</p>
          <p>{{ archetypes.zabot }}</p>
          <p>{{ archetypes.tvor }}</p>
          <p>{{ archetypes.prav }}</p>
          <p>{{ archetypes.mal }}</p>
          <p>{{ archetypes.love }}</p>
          <p>{{ archetypes.jester }}</p>
        </div>
      </div>
      <div class="main-mobile-diagram" style="display: none;">
        <img src="../assets/images/instructions-diagram.svg" alt="diagram" class="main-mobile-diagram-circle"/>
        <img
            src="../assets/images/diagram/nevin.svg"
            alt="diagram"
            class="diagram-circle-nevin"
            :style="{ zIndex: diagram.nevin.index - 1 }"
        />
        <img
            src="../assets/images/diagram/isk.svg"
            alt="diagram"
            class="diagram-circle-isk"
            :style="{ zIndex: diagram.isk.index - 1 }"
        />
        <img
            src="../assets/images/diagram/intel.svg"
            alt="diagram"
            class="diagram-circle-intel"
            :style="{ zIndex: diagram.mudr.index - 1 }"
        />
        <img
            src="../assets/images/diagram/hero.svg"
            alt="diagram"
            class="diagram-circle-hero"
            :style="{ zIndex: diagram.hero.index - 1 }"
        />
        <img
            src="../assets/images/diagram/izmen.svg"
            alt="diagram"
            class="diagram-circle-izmen"
            :style="{ zIndex: diagram.bunt.index - 1 }"
        />
        <img
            src="../assets/images/diagram/duh.svg"
            alt="diagram"
            class="diagram-circle-duh"
            :style="{ zIndex: diagram.mag.index - 1 }"
        />
        <img
            src="../assets/images/diagram/zabot.svg"
            alt="diagram"
            class="diagram-circle-zabot"
            :style="{ zIndex: diagram.zabot.index - 1 }"
        />
        <img
            src="../assets/images/diagram/tvor.svg"
            alt="diagram"
            class="diagram-circle-tvor"
            :style="{ zIndex: diagram.tvor.index - 1 }"
        />
        <img
            src="../assets/images/diagram/prav.svg"
            alt="diagram"
            class="diagram-circle-prav"
            :style="{ zIndex: diagram.prav.index - 1 }"
        />
        <img
            src="../assets/images/diagram/mal.svg"
            alt="diagram"
            class="diagram-circle-mal"
            :style="{ zIndex: diagram.mal.index - 1 }"
        />
        <img
            src="../assets/images/diagram/love.svg"
            alt="diagram"
            class="diagram-circle-love"
            :style="{ zIndex: diagram.love.index - 1 }"
        />
        <img
            src="../assets/images/diagram/jester.svg"
            alt="diagram"
            class="diagram-circle-jester"
            :style="{ zIndex: diagram.jester.index - 1 }"
        />
        <div
            class="diagram-circle-pouring diagram-circle-nevin-block"
            :style="{
              zIndex: diagram.nevin.index,
              boxShadow: '0 0 0 ' + diagram.nevin.mobileWidth + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-isk-block"
            :style="{
              zIndex: diagram.isk.index,
              boxShadow: '0 0 0 ' + diagram.isk.mobileWidth + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-intel-block"
            :style="{
              zIndex: diagram.mudr.index,
              boxShadow: '0 0 0 ' + diagram.mudr.mobileWidth + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-hero-block"
            :style="{
              zIndex: diagram.hero.index,
              boxShadow: '0 0 0 ' + diagram.hero.mobileWidth + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-izmen-block"
            :style="{
              zIndex: diagram.bunt.index,
              boxShadow: '0 0 0 ' + diagram.bunt.mobileWidth + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-duh-block"
            :style="{
              zIndex: diagram.mag.index,
              boxShadow: '0 0 0 ' + diagram.mag.mobileWidth + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-zabot-block"
            :style="{
              zIndex: diagram.zabot.index,
              boxShadow: '0 0 0 ' + diagram.zabot.mobileWidth + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-tvor-block"
            :style="{
              zIndex: diagram.tvor.index,
              boxShadow: '0 0 0 ' + diagram.tvor.mobileWidth + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-prav-block"
            :style="{
              zIndex: diagram.prav.index,
              boxShadow: '0 0 0 ' + diagram.prav.mobileWidth + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-mal-block"
            :style="{
              zIndex: diagram.mal.index,
              boxShadow: '0 0 0 ' + diagram.mal.mobileWidth + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-love-block"
            :style="{
              zIndex: diagram.love.index,
              boxShadow: '0 0 0 ' + diagram.love.mobileWidth + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div
            class="diagram-circle-pouring diagram-circle-jester-block"
            :style="{
              zIndex: diagram.jester.index,
              boxShadow: '0 0 0 ' + diagram.jester.mobileWidth + 'px rgba(40, 49, 82, 1) inset'
            }"
        ></div>
        <div class="diagram-circle-numbers">
          <p>{{ archetypes.nevin }}</p>
          <p>{{ archetypes.isk }}</p>
          <p>{{ archetypes.mudr }}</p>
          <p>{{ archetypes.hero }}</p>
          <p>{{ archetypes.bunt }}</p>
          <p>{{ archetypes.mag }}</p>
          <p>{{ archetypes.zabot }}</p>
          <p>{{ archetypes.tvor }}</p>
          <p>{{ archetypes.prav }}</p>
          <p>{{ archetypes.mal }}</p>
          <p>{{ archetypes.love }}</p>
          <p>{{ archetypes.jester }}</p>
        </div>
      </div>
      <div class="results-left-video">
        <img src="../assets/images/results-anna-with-book.webp" alt="anna">
        <div class="results-left-video-block">
          <h3>
            СКАЧАЙТЕ ГАЙД С РАСШИФРОВКОЙ
          </h3>
          <hr class="hr">
          <p>
            ✔️ Подробное описание показателей раскрытия лидерского потенциала + рекомендации по балансировке. <br><br>
            ✔️ Подсказки по уточнению типа лидерства через ключевой вектор.
          </p>
          <a href="https://t.me/zavadskaya_bot?start=136767" target="_blank" class="button">СКАЧАТЬ РАСШИФРОВКУ</a>
      </div>
        </div>
    </div>
    <div class="results-right">
      <div class="results-right-block">
        <h3>Потенциал лидера</h3>
        <hr class="hr">
        <p class="results-right-block-p">
          {{ indicators.potentialActivation.text }}
          <a :href="indicators.potentialActivation.link" style="color: #fec7b6">{{ indicators.potentialActivation.textLink }}</a>{{ indicators.potentialActivation.nextText }}
        </p>
        <img src="../assets/images/results-hexagon.svg" alt="hexagon">
        <p class="results-right-block-percent">{{ indicators.potentialActivation.percent }}%</p>
      </div>
      <div class="results-right-block">
        <h3>Гибкость лидера</h3>
        <hr class="hr">
        <p class="results-right-block-p">
          {{ indicators.internalImbalance.text }}
        </p>
        <img src="../assets/images/results-hexagon.svg" alt="hexagon" style="top: -23px">
        <p class="results-right-block-percent">{{ indicators.internalImbalance.percent }}%</p>
      </div>
      <div class="results-right-block">
        <h3>Эффективность лидера</h3>
        <hr class="hr">
        <p class="results-right-block-p">
          {{ indicators.lifeManagement.text }}
          <a :href="indicators.lifeManagement.link" style="color: #fec7b6">{{ indicators.lifeManagement.textLink }}</a>{{ indicators.lifeManagement.nextText }}
        </p>
        <img src="../assets/images/results-hexagon.svg" alt="hexagon" style="top: -23px;">
        <p class="results-right-block-percent">{{ indicators.lifeManagement.percent }}%</p>
      </div>
      <div class="results-right-block">
        <h3>Ресурс лидера</h3>
        <hr class="hr">
        <p class="results-right-block-p">
          {{ indicators.easeOfLife.text }}
          <a :href="indicators.easeOfLife.link" style="color: #fec7b6">{{ indicators.easeOfLife.textLink }}</a>{{ indicators.easeOfLife.nextText }}
        </p>
        <img src="../assets/images/results-hexagon.svg" alt="hexagon">
        <p class="results-right-block-percent">{{ indicators.easeOfLife.percent }}%</p>
      </div>
      <div class="results-right-mobile-video" style="display: none;">
        <div class="results-right-mobile-video-block">
          <h3>
            СКАЧАЙТЕ ГАЙД С РАСШИФРОВКОЙ
          </h3>
          <hr class="hr">
          <p>
            ✔️ Подробное описание показателей раскрытия лидерского потенциала + рекомендации по балансировке. <br><br>
            ✔️ Подсказки по уточнению типа лидерства через ключевой вектор.
          </p>
          <a href="https://t.me/zavadskaya_bot?start=136767" target="_blank" class="button">СКАЧАТЬ РАСШИФРОВКУ</a>
        </div>
      </div>
    </div>
  </div>
  <div class="bottom-results-mobile" style="display: none;">
    <h3 style="margin-top: 46px; margin-bottom: 6px;">БАЗОВЫЙ ВЕКТОР ЛИДЕРСТВА</h3>
    <div class="bottom-results-mobile-block">
      <h3>ВЗАИМОДЕЙСТВИЕ</h3>
      <hr class="hr">
      <p class="bottom-results-mobile-block-p" v-html="indicators.selfActualization.text"></p>
      <img src="../assets/images/results-hexagon.svg" alt="hexagon">
      <p class="results-right-block-percent">{{ indicators.selfActualization.percent }}%</p>
    </div>
    <div class="bottom-results-mobile-block">
      <h3>ИЗМЕНЕНИЯ</h3>
      <hr class="hr">
      <p class="bottom-results-mobile-block-p" v-html="indicators.changes.text"></p>
      <img src="../assets/images/results-hexagon.svg" alt="hexagon">
      <p class="results-right-block-percent">{{ indicators.changes.percent }}%</p>
    </div>
    <div class="bottom-results-mobile-block">
      <h3>СТАБИЛЬНОСТЬ</h3>
      <hr class="hr">
      <p class="bottom-results-mobile-block-p" v-html="indicators.safety.text"></p>
      <img src="../assets/images/results-hexagon.svg" alt="hexagon">
      <p class="results-right-block-percent">{{ indicators.safety.percent }}%</p>
    </div>
    <h3>ВЕДУЩИЕ СТРАТЕГИИ ЛИДЕРСТВА</h3>
    <div class="bottom-results-mobile-archetypes">
      <div class="bottom-results-mobile-archetypes-info">
        <div class="bottom-results-mobile-archetypes-info-block" v-for="(value, key) in mobileBlocks" :key="key">
          <p>{{ archetypes[key] }}</p>
          <hr class="hr">
          <div>
            <div class="bottom-results-mobile-archetypes-info-block-div">
              <img
                  :src="'https://annazavadskaya.ru/icons/mobile/' + value.image"
                  :alt="key"
              >
            </div>
            <p class="bottom-results-mobile-archetypes-info-block-p" v-html="value.name"></p>
          </div>
          <Transition name="mobile-block">
            <div
                class="bottom-results-mobile-archetypes-describe"
            >
              <div :style="{ right: '268px' }"></div>
              <p>
                {{ value.text }}
                <a :href="value.link" target="_blank"><b>Перейти к описанию >>></b></a>
              </p>
            </div>
          </Transition>
        </div>
      </div>
    </div>
  </div>
  <h3 class="bottom-results-h3">БАЗОВЫЙ ВЕКТОР ЛИДЕРСТВА</h3>
  <div class="bottom-results">
    <div class="bottom-results-block">
      <img src="../assets/images/results-hexagon.svg" alt="hexagon">
      <p class="bottom-results-block-percent">{{ indicators.selfActualization.percent }}%</p>
      <h3>ВЗАИМОДЕЙСТВИЕ</h3>
      <hr class="hr">
      <p class="bottom-results-block-p" v-html="indicators.selfActualization.text">
      </p>
    </div>
    <div class="bottom-results-block">
      <img src="../assets/images/results-hexagon.svg" alt="hexagon">
      <p class="bottom-results-block-percent">{{ indicators.changes.percent }}%</p>
      <h3>ИЗМЕНЕНИЯ</h3>
      <hr class="hr">
      <p class="bottom-results-block-p" v-html="indicators.changes.text"></p>
    </div>
    <div class="bottom-results-block">
      <img src="../assets/images/results-hexagon.svg" alt="hexagon">
      <p class="bottom-results-block-percent">{{ indicators.safety.percent }}%</p>
      <h3>СТАБИЛЬНОСТЬ</h3>
      <hr class="hr">
      <p class="bottom-results-block-p" v-html="indicators.safety.text"></p>
    </div>
  </div>
  <div class="archetypes">
    <div class="archetypes-block" v-for="(value, key) in enabledBlocks" :key="key">
      <div class="archetypes-block-image">
        <img :src="'https://annazavadskaya.ru/icons/' + value.image" :alt="key">
      </div>
      <hr class="hr">
      <p class="archetypes-block-percent">{{ value.points }}</p>
      <h3 v-html="value.name + ':'"></h3>
      <p class="archetypes-block-p">
        {{ value.text }}
        <a :href="value.link" target="_blank"><b>Перейти к описанию >>></b></a>
      </p>
    </div>
  </div>
  <div class="book">
    <hr class="hr">
    <div class="book-light"></div>
    <img src="../assets/images/results-anna-bottom.webp" alt="Анна Завадская" class="book-img">
    <div class="book-set">
      <div class="book-set-block">
        <p>
          Скачайте план по построению <span>ЛИЧНОГО БРЕНДА</span> через архетипы в телеграм
        </p>
        <a href="https://t.me/zavadskaya_bot?start=22369" target="_blank" class="button">СКАЧАТЬ ПЛАН</a>
      </div>
    </div>
    <hr class="hr book-hr">
  </div>
  <div class="book-mobile" style="display: none;">
    <p>
      Скачайте план по построению <span>ЛИЧНОГО БРЕНДА</span> через архетипы в телеграм
    </p>
    <img src="../assets/images/mobile/results-arrow-bottom.svg" alt="arrow">
    <a href="https://t.me/zavadskaya_bot?start=22369" target="_blank" class="button">СКАЧАТЬ ПЛАН</a>

    <div class="book-mobile-anna">
      <div class="book-mobile-anna-light"></div>
      <img src="../assets/images/mobile/results-anna.webp" alt="anna" class="book-mobile-anna-img">
    </div>
  </div>
  <footer>
    <div class="footer">
      <div class="footer-text">
        © 2019 zavadskaya.online <br>
        Авторские права защищены <br>
        Копирование запрещено
      </div>
      <div class="footer-links">
        <a href="https://t.me/zavadskaya12D" target="_blank">
          <img src="../assets/images/results-tg.svg" alt="Telegram" class="footer-links">
        </a>
      </div>
    </div>
  </footer>

  <div class="mobile-links" style="display: none;">
    <div>
      <a href="https://t.me/zavadskaya12D" target="_blank">
        <img src="../assets/images/results-tg.svg" alt="Telegram">
      </a>
    </div>
  </div>
</body>
</template>

<script>
import router from "@/router";

export default {
  name: "ResultsView",
  data() {
    return {
      mobileText: [
        // Невинный
        'Жизнерадостный, воодушевляющий мечтатель.\n' +
        'Вносит легкость в деловую коммуникацию и упрощает сложные процессы.',
        // Искатель
        'Проводник к новым технологиям и знаниям.\n' +
        'Знает в какую сторону движутся тенденции и становится первопроходцем в любой сфере.',
        // Мудрец
        'Лидер мнений, который несет достоверные знания в массы.\n' +
        'Помогает найти ответы на интересующие вопросы и обрести истинную мудрость.',
        // Герой
        'Амбициозный лидер, который становится мотивирующим примером для окружающих в создании успешных и прорывных проектов.',
        // Бунтарь
        'Революционер, который активно реализует свое видение мира, разрушая на пути все преграды и стереотипы.',
        // Маг
        'Харизматичный лидер, который трансформирует всё, к чему прикасается и всех, кто попадает в поле его влияния.',
        // Заботливый
        'Внимательный лидер, сфокусированный на создании комфортной среды, образовательных и социально значимых проектов.',
        // Творец
        'Креативный лидер, который увлечен идеей и ее реализацией.\n' +
        'Активно работает над уникальностью и прикладным применением продукта.',
        // Правитель
        'Влиятельный лидер, который выстраивает стратегию на перспективу, успешно управляет командой и планомерно растет.',
        // Сл. малый
        'Открыт к общению, эффективно объединяет людей.\n' +
        'Создает взаимовыгодные отношения и формирует командное единство.',
        // Любовник
        'Притягательный лидер, который выстраивает доверительную коммуникацию.\n' +
        'Эффектно презентует проекты и идеи.',
        // Шут
        'Эпатажный лидер, который умело вовлекает в происходящее, шокирует и удивляет нестандартными решениями и формами взаимодействия.'
      ],
      mobileLinks: [
        // Невинный
        'https://zavadskaya.online/arxetip-nevinnyj-prostodushnyj',
        // Искатель
        'https://zavadskaya.online/arxetip-iskatel-strannik',
        // Мудрец
        'https://zavadskaya.online/arxetip-mudrec-filosof',
        // Герой
        'https://zavadskaya.online/arxetip-geroj-voin',
        // Бунтарь
        'https://zavadskaya.online/arxetip-buntar-izgoj',
        // Маг
        'https://zavadskaya.online/arxetip-mag-volshebnik',
        // Заботливый
        'https://zavadskaya.online/arxetip-zabotlivyj-xranitel',
        // Творец
        'https://zavadskaya.online/arxetip-tvorec',
        // Правитель
        'https://zavadskaya.online/arxetip-pravitel',
        // Сл. малый
        'https://zavadskaya.online/arxetip-slavnyj-malyj',
        // Любовник
        'https://zavadskaya.online/arxetip-lyubovnik-estet',
        // Шут
        'https://zavadskaya.online/arxetip-shut'
      ],
      mobileBlocks: {},
      blocks: {
        bunt: {
          name: "Реформирующий лидер (Бунтарь)",
          points: 0,
          text: 'Революционер, который активно реализует свое видение мира,\n' +
              'разрушая на пути все преграды и стереотипы.',
          link: 'https://zavadskaya.online/arxetip-buntar-izgoj',
          image: "results-bunt.svg",
          isEnabled: false
        },
        hero: {
          name: "Совершенствующий лидер (Герой)",
          points: 0,
          text: 'Амбициозный лидер,\n' +
              'который становится мотивирующим примером для окружающих в создании успешных и прорывных проектов.',
          link: 'https://zavadskaya.online/arxetip-geroj-voin',
          image: "results-hero.svg",
          isEnabled: false
        },
        isk: {
          name: "Исследующий лидер (Искатель)",
          points: 0,
          text: 'Проводник к новым технологиям и знаниям.\n' +
              'Знает в какую сторону движутся тенденции и становится первопроходцем в любой сфере.',
          link: 'https://zavadskaya.online/arxetip-iskatel-strannik',
          image: "results-isk.svg",
          isEnabled: false
        },
        jester: {
          name: "Вовлекающий лидер (Шут)",
          points: 0,
          text: 'Эпатажный лидер, который умело вовлекает в происходящее,\n' +
              'шокирует и удивляет нестандартными решениями и формами взаимодействия.',
          link: 'https://zavadskaya.online/arxetip-shut',
          image: "results-shut.svg",
          isEnabled: false
        },
        love: {
          name: "Влюбляющий лидер (Любовник)",
          points: 0,
          text: 'Притягательный лидер, который выстраивает доверительную коммуникацию.\n' +
              'Эффектно презентует проекты и идеи.',
          link: 'https://zavadskaya.online/arxetip-lyubovnik-estet',
          image: "results-love.svg",
          isEnabled: false
        },
        mag: {
          name: "Трансформирующий лидер (Маг)",
          points: 0,
          text: 'Харизматичный лидер, который трансформирует всё,\n' +
              'к чему прикасается и всех, кто попадает в поле его влияния.',
          link: 'https://zavadskaya.online/arxetip-mag-volshebnik',
          image: "results-mag.svg",
          isEnabled: false
        },
        mal: {
          name: "Объединяющий лидер (Славный Малый)",
          points: 0,
          text: 'Открыт к общению, эффективно объединяет людей.\n' +
              'Создает взаимовыгодные отношения и формирует командное единство.',
          link: 'https://zavadskaya.online/arxetip-slavnyj-malyj',
          image: "results-mal.svg",
          isEnabled: false
        },
        mudr: {
          name: "Анализирующий лидер (Мудрец)",
          points: 0,
          text: 'Лидер мнений, который несет достоверные знания в массы.\n' +
              'Помогает найти ответы на интересующие вопросы и обрести истинную мудрость.',
          link: 'https://zavadskaya.online/arxetip-mudrec-filosof',
          image: "results-mudr.svg",
          isEnabled: false
        },
        nevin: {
          name: "Вдохновляющий лидер (Невинный)",
          points: 0,
          text: 'Жизнерадостный, воодушевляющий мечтатель.\n' +
              'Вносит легкость в деловую коммуникацию и упрощает сложные процессы.',
          link: 'https://zavadskaya.online/arxetip-nevinnyj-prostodushnyj',
          image: "results-nevin.svg",
          isEnabled: false
        },
        prav: {
          name: "Масштабирующий лидер (Правитель)",
          points: 0,
          text: 'Влиятельный лидер, который выстраивает стратегию на перспективу,\n' +
              'успешно управляет командой и планомерно растет.',
          link: 'https://zavadskaya.online/arxetip-pravitel',
          image: "results-prav.svg",
          isEnabled: false
        },
        tvor: {
          name: "Генерирующий лидер (Творец)",
          points: 0,
          text: 'Креативный лидер, который увлечен идеей и ее реализацией.\n' +
              'Активно работает над уникальностью и прикладным применением продукта.',
          link: 'https://zavadskaya.online/arxetip-tvorec',
          image: "results-tvor.svg",
          isEnabled: false
        },
        zabot: {
          name: "Поддерживающий лидер (Заботливый)",
          points: 0,
          text: 'Внимательный лидер, сфокусированный на создании комфортной среды,\n' +
              'образовательных и социально значимых проектов.',
          link: 'https://zavadskaya.online/arxetip-zabotlivyj-xranitel',
          image: "results-zabot.svg",
          isEnabled: false
        },
      },
      currentTriangle: 0,
      currentDisplay: {
        one: false,
        two: false,
        three: false,
        four: false
      },
      mobileTriangle: [264, 148, 34],
      mobileState: -1,
      session: this.$route.params.session,
      indicators: {
        potentialActivation: {
          percent: 0,
          text: '',
          nextText: '',
          link: '',
          textLink: ''
        },
        internalImbalance: {
          percent: 0,
          text: ''
        },
        lifeManagement: {
          percent: 0,
          text: '',
          nextText: '',
          link: '',
          textLink: ''
        },
        easeOfLife: {
          percent: 0,
          text: '',
          nextText: '',
          link: '',
          textLink: ''
        },
        selfActualization: {
          percent: 0,
          text: ''
        },
        changes: {
          percent: 0,
          text: ''
        },
        safety: {
          percent: 0,
          text: ''
        },
      },
      archetypes: {
        nevin: 0,
        mal: 0,
        hero: 0,
        zabot: 0,
        isk: 0,
        bunt: 0,
        love: 0,
        tvor: 0,
        prav: 0,
        mag: 0,
        mudr: 0,
        jester: 0
      },
      diagram: {
        nevin: {
          index: 1,
          width: 1,
          mobileWidth: 1
        },
        mal: {
          index: 1,
          width: 1,
          mobileWidth: 1
        },
        hero: {
          index: 1,
          width: 1,
          mobileWidth: 1
        },
        zabot: {
          index: 1,
          width: 1,
          mobileWidth: 1
        },
        isk: {
          index: 1,
          width: 1,
          mobileWidth: 1
        },
        bunt: {
          index: 1,
          width: 1,
          mobileWidth: 1
        },
        love: {
          index: 1,
          width: 1,
          mobileWidth: 1
        },
        tvor: {
          index: 1,
          width: 1,
          mobileWidth: 1
        },
        prav: {
          index: 1,
          width: 1,
          mobileWidth: 1
        },
        mag: {
          index: 1,
          width: 1,
          mobileWidth: 1
        },
        mudr: {
          index: 1,
          width: 1,
          mobileWidth: 1
        },
        jester: {
          index: 1,
          width: 1,
          mobileWidth: 1
        },
        currentIndex: 2,
        circle: [135, 120, 109, 98, 86, 74, 62, 51, 39, 28, 0],
        circleMobile: [73, 64, 57, 50, 43, 36, 29, 22, 15, 8, 0],
      },
    }
  },
  methods: {
    getMobileBlocks() {
      const enabledItems = Object.entries(this.blocks)
          .filter(([, item]) => item.isEnabled)
          .sort(([, itemA], [, itemB]) => itemB.points - itemA.points) // Сортировка по количеству баллов
          .slice(0, 3);

      // Удаление четвертого элемента, если он существует
      if (enabledItems.length >= 4) {
        enabledItems.splice(3, 1);
      }

      // Преобразовываем обратно в объект
      return Object.fromEntries(enabledItems);
    },
  },
  computed: {
    enabledBlocks() {
      return Object.entries(this.blocks)
          .filter(([, block]) => block.isEnabled)
          .sort(([, blockA], [, blockB]) => blockB.points - blockA.points) // Сортировка по количеству баллов
          .reduce((obj, [key, block]) => {
            obj[key] = block;
            return obj;
          }, {});
    },
  },
  async beforeMount() {
    await isActivated(this);
    await getResult(this);
  },
}

function isActivated(app) {
  app.axios.post("https://dev.annazavadskaya.ru:3000/api/user/activate/get", { id: app.session })
    .then((res) => {
      if (!res.data) {
        return router.push({name: 'home'});
      }
  });
}

async function getResult(app) {
  app.axios.post("https://dev.annazavadskaya.ru:3000/api/results/", { id: app.session })
  .then(async (res) => {
    // app.archetypes = {bunt: 55, hero: 54, isk: 53, jester: 52, love: 0, mag: 0, mal: 0, mudr: 0, nevin: 0, prav: 0, tvor: 0, zabot: 0};
    app.archetypes = {...res.data};

    if (app.archetypes.nevin === 0 && app.archetypes.mal === 0 && app.archetypes.hero === 0 && app.archetypes.zabot === 0) {
      return router.push({name: 'home'});
    }

    let conclusion = await findTop(app.archetypes, 4);

    conclusion.forEach((value) => {
      app.blocks[value].isEnabled = true;
      app.blocks[value].points = app.archetypes[value];
    });

    app.mobileBlocks = await app.getMobileBlocks();

    calculateDiagram(app);

    app.indicators.potentialActivation.percent = calculatePotentialActivation({...app.archetypes});
    app.indicators.potentialActivation.text = textPotentialActivation(app.indicators.potentialActivation.percent, app);

    app.indicators.internalImbalance.percent = calculateInternalImbalance({...app.archetypes});
    app.indicators.internalImbalance.text = textInternalImbalance(app.indicators.internalImbalance.percent);

    app.indicators.lifeManagement.percent = calculateLifeManagement({...app.archetypes});
    app.indicators.lifeManagement.text = textLifeManagement(app.indicators.lifeManagement.percent, app);

    app.indicators.easeOfLife.percent = calculateEaseOfLife({...app.archetypes});
    app.indicators.easeOfLife.text = textEaseOfLife(app.indicators.easeOfLife.percent, app);

    app.indicators.selfActualization.percent = calculateSelfActualization({...app.archetypes});
    app.indicators.selfActualization.text = `Глобальный вектор на взаимодействие с миром.
    Показывает, насколько активно вы коммуницируете и сближаетесь с окружающими.`;

    app.indicators.changes.percent = calculateChanges({...app.archetypes});
    app.indicators.changes.text = `Глобальный вектор на изменения и прорывной рост.
    Показывает, насколько активно вы  вносите изменения и управляете ими.`;

    app.indicators.safety.percent = calculateSafety({...app.archetypes});
    app.indicators.safety.text = `Глобальный вектор на контроль и безопасность.
    Показывает, насколько активно вы стремитесь создать благополучие и процветание.`;

  });
}

function findTop(obj, topVal) {
  return Object.keys(obj)
      .sort((a, b) => obj[b] - obj[a])
      .slice(0, topVal);
}

// Потенциал лидера
function calculatePotentialActivation({...data}) {
  let result = (data.nevin + data.mal + data.hero + data.zabot + data.isk
      + data.bunt + data.love + data.tvor + data.prav + data.mag + data.mudr + data.jester) / 12;

  return Math.round(result);
}

// Гибкость лидера
function calculateInternalImbalance({...data}) {
  let arr = Object.values(data);
  return 100 - (Math.max(...arr) - Math.min(...arr));
}

// Эффективность лидера
function calculateLifeManagement({...data}) {
  let result = (data.prav + data.mudr + data.mag + data.hero) / 4;
  return Math.round(result);
}

// Ресурс лидера
function calculateEaseOfLife({...data}) {
  let result = (data.nevin + data.zabot + data.love + data.jester) / 4;
  return Math.round(result);
}

// ВЗАИМОДЕЙСТВИЕ
function calculateSelfActualization({...data}) {
  let result = (data.nevin + data.mal + data.love + data.jester) / 4;
  return Math.round(result);
}

// ИЗМЕНЕНИЯ
function calculateChanges({...data}) {
  let result = (data.hero + data.isk + data.bunt + data.mag) / 4;
  return Math.round(result);
}

// СТАБИЛЬНОСТЬ
function calculateSafety({...data}) {
  let result = (data.zabot + data.tvor + data.prav + data.mudr) / 4;
  return Math.round(result);
}

function calculateDiagram(app) {
    let nevin = Math.floor(app.archetypes.nevin / 10);
    let mal = Math.floor(app.archetypes.mal / 10);
    let hero = Math.floor(app.archetypes.hero / 10);
    let zabot = Math.floor(app.archetypes.zabot / 10);
    let isk = Math.floor(app.archetypes.isk / 10);
    let bunt = Math.floor(app.archetypes.bunt / 10);
    let love = Math.floor(app.archetypes.love / 10);
    let tvor = Math.floor(app.archetypes.tvor / 10);
    let prav = Math.floor(app.archetypes.prav / 10);
    let mag = Math.floor(app.archetypes.mag / 10);
    let mudr = Math.floor(app.archetypes.mudr / 10);
    let jester = Math.floor(app.archetypes.jester / 10);

  let array = [nevin, mal, hero, zabot, isk, bunt, love, prav, tvor, mag, mudr, jester];
  let arrayText = ["nevin", "mal", "hero", "zabot", "isk", "bunt", "love", "prav", "tvor", "mag", "mudr", "jester"];

  for (let i = 0; i < 11; i++) {
    let res = Math.min(...array);
    let resArray = [];
    if (res !== 15) {
      array.forEach((el, index, arr) => {
        if (el === res) {
          resArray.push(index);
          arr[index] = 15;
        }
      });
    }

    if (resArray.length !== 0) {
      if (resArray.length > 1) {
        resArray.forEach((el) => {
          app.diagram[arrayText[el]].index = app.diagram.currentIndex + 2;
        });
        app.diagram.currentIndex += 2;
      } else {
        app.diagram[arrayText[resArray[0]]].index = app.diagram.currentIndex;
      }
    }

    app.diagram.currentIndex += 2;
  }

  app.diagram.nevin.width = app.diagram.circle[nevin];
  app.diagram.mal.width = app.diagram.circle[mal];
  app.diagram.hero.width = app.diagram.circle[hero];
  app.diagram.zabot.width = app.diagram.circle[zabot];
  app.diagram.isk.width = app.diagram.circle[isk];
  app.diagram.bunt.width = app.diagram.circle[bunt];
  app.diagram.love.width = app.diagram.circle[love];
  app.diagram.tvor.width = app.diagram.circle[tvor];
  app.diagram.prav.width = app.diagram.circle[prav];
  app.diagram.mag.width = app.diagram.circle[mag];
  app.diagram.mudr.width = app.diagram.circle[mudr];
  app.diagram.jester.width = app.diagram.circle[jester];

  app.diagram.nevin.mobileWidth = app.diagram.circleMobile[nevin];
  app.diagram.mal.mobileWidth = app.diagram.circleMobile[mal];
  app.diagram.hero.mobileWidth = app.diagram.circleMobile[hero];
  app.diagram.zabot.mobileWidth = app.diagram.circleMobile[zabot];
  app.diagram.isk.mobileWidth = app.diagram.circleMobile[isk];
  app.diagram.bunt.mobileWidth = app.diagram.circleMobile[bunt];
  app.diagram.love.mobileWidth = app.diagram.circleMobile[love];
  app.diagram.tvor.mobileWidth = app.diagram.circleMobile[tvor];
  app.diagram.prav.mobileWidth = app.diagram.circleMobile[prav];
  app.diagram.mag.mobileWidth = app.diagram.circleMobile[mag];
  app.diagram.mudr.mobileWidth = app.diagram.circleMobile[mudr];
  app.diagram.jester.mobileWidth = app.diagram.circleMobile[jester];
}

// Потенциал лидера
function textPotentialActivation(percent, app) {
  if (percent <= 50) {
    app.indicators.potentialActivation.link = 'https://zavadskaya.online/rasp12d';
    app.indicators.potentialActivation.textLink = 'здесь';
    app.indicators.potentialActivation.nextText = '';

    return "Потенциал вашего лидерства пока не проявлен в мир. " +
        "Вам необходима опорная система смыслов и ценностей, которую можно сформировать ";
  } else if (percent >= 51 && percent <= 80) {
    app.indicators.potentialActivation.link = 'https://zavadskaya.online/rasp12d';
    app.indicators.potentialActivation.textLink = 'здесь';
    app.indicators.potentialActivation.nextText = '';

    return "Ваш потенциал сдерживают спящие архетипы личности. " +
        "Активировать заблокированные ресурсы и создать их синергетичное партнерство можно ";
  } else {
    app.indicators.potentialActivation.link = 'https://zavadskaya.online/rasp12d';
    app.indicators.potentialActivation.textLink = 'Подробнее';
    app.indicators.potentialActivation.nextText = '';

    return "Ваш потенциал реализуется отлично. " +
        "Предлагаю вам раскрыть сверхпотенциал лидера, управляя системой внутренних ресурсов - архетипов. ";
  }
}

// Гибкость лидера
function textInternalImbalance(percent) {
  if (percent <= 50) {
    return "У вас есть жесткие структуры, которые мешают вам быстро адаптироваться к изменениям. " +
        "Это может приводить к выгоранию и внутренним конфликтам.";
  } else if (percent >= 51 && percent <= 80) {
    return "Средняя гибкость и адаптивность, нужно обратить внимание на спящие архетипы и задействовать их. " +
        "Так вы получите больше внутренних ресурсов и работающих стратегий.";
  } else {
    return "Вы можете задействовать любую стратегию, гибко переключаясь между ними. " +
        "Это дает хороший внутренний баланс и гармоничное развитие.";
  }
}

// Эффективность лидера
function textLifeManagement(percent, app) {
  if (percent <= 50) {
    app.indicators.lifeManagement.link = 'https://zavadskaya.online/rasp12d';
    app.indicators.lifeManagement.textLink = 'здесь';
    app.indicators.lifeManagement.nextText = '';

    return "Слабый внутренний контроль. " +
        "Скорее всего, вас регулярно выбивают из рабочего режима деструктивные эмоции и ситуации. " +
        "Управляйте внутренними ресурсами, как командой. Подробнее ";
  } else if (percent >= 51 && percent <= 80) {
    app.indicators.lifeManagement.link = 'https://zavadskaya.online/rasp12d';
    app.indicators.lifeManagement.textLink = 'здесь';
    app.indicators.lifeManagement.nextText = '';

    return "Хороший контроль. " +
        "Вы можете быстро прийти в продуктивное состояние. " +
        "Это можно делать осознанно, включая нужные стратегии под задачу. Подробнее  ";
  } else {
    app.indicators.lifeManagement.link = 'https://zavadskaya.online/rasp12d';
    app.indicators.lifeManagement.textLink = 'здесь';
    app.indicators.lifeManagement.nextText = '';

    return "Вы оптимально задействуете ресурсы личности, поэтому предлагаю вам еще и системно управлять внутренними стратегиями. " +
        "Подробнее ";
  }
}

// Ресурс лидера
function textEaseOfLife(percent, app) {
  if (percent <= 50) {
    app.indicators.easeOfLife.link = '';
    app.indicators.easeOfLife.textLink = '';
    app.indicators.easeOfLife.nextText = '';

    return "Высокий уровень напряжения. " +
        "Обратите внимание на обязательный отдых и восполнение ресурса, чтобы избежать выгорания.";
  } else if (percent >= 51 && percent <= 80) {
    app.indicators.easeOfLife.link = '';
    app.indicators.easeOfLife.textLink = '';
    app.indicators.easeOfLife.nextText = '';

    return "Вы гармонично выстраиваете режим работы и отдыха, однако иногда можете отдавать излишний приоритет отдыху и развлечениям.";
  } else {
    app.indicators.easeOfLife.link = '';
    app.indicators.easeOfLife.textLink = '';
    app.indicators.easeOfLife.nextText = '';

    return "Отличный результат. " +
        "Вы можете легко восполнить ресурс, переключиться на отдых, снизить уровень стресса.";
  }
}

</script>

<style lang="scss" scoped>
@import "/src/assets/css/results.scss";
</style>