<template>
  <body>
  <h2>Тест на Архетипы 12D</h2>
  <p class="question">
    Укажите ваш емейл, чтобы получить <span>копию результатов на почту</span>.
    Если вы пройдете тест повторно, то сможете сравнить показатели и отследить динамику развития.
  </p>
  <div v-if="form.errors.length" class="error">
    <b>Пожалуйста исправьте указанные ошибки:</b>
    <p v-for="error in form.errors" :key="error">{{ error }}</p>
  </div>
  <div class="buttons">
    <form method="post" class="buttons-form" @submit.prevent="checkEmail">
      <input type="email" class="buttons-form-input" :disabled="form.isDisabled" placeholder="Ваш e-mail" v-model="form.email">
      <input type="submit" class="buttons-form-submit button" :disabled="form.isDisabled" value="ОТПРАВИТЬ">
    </form>
  </div>
  <p class="alert">
    Проверьте, <u>верно ли указан емейл</u>, чтобы письмо с результатами обязательно дошло до вас.
  </p>
  <p class="notify">
    * Нажимая на кнопку ОТПРАВИТЬ, вы соглашаетесь с обработкой персональных данных и
    <a href="https://zavadskaya.online/politika" target="_blank">Политикой конфиденциальности</a>,
    а также подписываетесь на email-рассылку Анны Завадской
  </p>
  </body>
</template>

<script>
import Cookies from 'js-cookie';
import router from "@/router";

export default {
  name: "TestView",
  data() {
    return {
      form: {
        email: '',
        isDisabled: false,
        errors: []
      },
      session: Cookies.get("session")
    }
  },
  methods: {
    async checkEmail() {
      if (!this.validEmail(this.form.email)) {
        this.form.errors.push("Укажите верный e-mail адрес.");
        setTimeout(() => {
          this.form.errors.length = 0; // очистка массива с ошибками
        }, 5000);
        return false;
      } else {
        this.form.isDisabled = true;
        await this.axios.post("https://dev.annazavadskaya.ru:3000/api/user/email/check",
            {
              id: this.session,
              email: this.form.email
            })
            .then((res) => {
              if (res.data) {
                this.axios.post('https://dev.annazavadskaya.ru:3000/api/results/send', { id: this.session })
                    .then((res)=> {
                      if (res.data) {
                        this.redirectToResults();
                      }
                    });
              }
            });
      }
    },
    validEmail: function (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    async redirectToResults() {
      this.form.isDisabled = true;
      let currentSession = this.session;
      Cookies.set('session', '1', {expires: -1});
      setTimeout(() => {
        return router.push({name: "results-cut", params: { session: currentSession} });
      }, 1000)
    }
  },
  beforeMount() {
    if (this.session === undefined) {
      return router.push({name: 'home'});
    }
  }
}
</script>

<style lang="scss" scoped>
@import "/src/assets/css/email.scss";
</style>