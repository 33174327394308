<template>
  <body>
    <header class="header">
      <img
          src="../assets/images/arrow-left.svg"
          alt="arrow-left"
      />
      <h2 class="header-h2">
        Как пройти тест эффективно:
      </h2>
      <img
          src="../assets/images/arrow-right.svg"
          alt="arrow-right"
      />
    </header>
    <div class="main">
      <div class="main-mobile" style="display:none;">
        <img
            src="../assets/images/mobile/instructions-time.svg"
            alt="time"
        />
        <p class="main-text main-mobile-p-desc">
          В тесте 60 вопросов, проведите 10 приятных минут с чашечкой кофе, чтобы увидеть свои ведущие архетипы.
        </p>
        <img
          src="../assets/images/mobile/instructions-bar.svg"
          alt="bar"
          class="main-mobile-bar"
        />
        <p class="main-text main-mobile-p-desc">
          Вопросы составлены в виде утверждений, на которые нужно выбрать подходящий балл от 0 до 10. <br><br>
          Балльная система дает более точно представление о расстановке сил в вашей системе личности.
        </p>
        <div class="main-mobile-diagram">
          <img
              src="../assets/images/instructions-diagram.svg"
              alt="diagram"
              class="main-mobile-diagram-circle"
          />
          <img src="../assets/images/diagram/nevin.svg" alt="diagram" class="diagram-circle-nevin"/>
          <img src="../assets/images/diagram/isk.svg" alt="diagram" class="diagram-circle-isk"/>
          <img src="../assets/images/diagram/intel.svg" alt="diagram" class="diagram-circle-intel"/>
          <img src="../assets/images/diagram/hero.svg" alt="diagram" class="diagram-circle-hero"/>
          <img src="../assets/images/diagram/izmen.svg" alt="diagram" class="diagram-circle-izmen"/>
          <img src="../assets/images/diagram/duh.svg" alt="diagram" class="diagram-circle-duh"/>
          <img src="../assets/images/diagram/zabot.svg" alt="diagram" class="diagram-circle-zabot"/>
          <img src="../assets/images/diagram/tvor.svg" alt="diagram" class="diagram-circle-tvor"/>
          <img src="../assets/images/diagram/prav.svg" alt="diagram" class="diagram-circle-prav"/>
          <img src="../assets/images/diagram/mal.svg" alt="diagram" class="diagram-circle-mal"/>
          <img src="../assets/images/diagram/love.svg" alt="diagram" class="diagram-circle-love"/>
          <img src="../assets/images/diagram/jester.svg" alt="diagram" class="diagram-circle-jester"/>
          <div class="diagram-circle-pouring diagram-circle-nevin-block"></div>
          <div class="diagram-circle-pouring diagram-circle-isk-block"></div>
          <div class="diagram-circle-pouring diagram-circle-intel-block"></div>
          <div class="diagram-circle-pouring diagram-circle-hero-block"></div>
          <div class="diagram-circle-pouring diagram-circle-izmen-block"></div>
          <div class="diagram-circle-pouring diagram-circle-duh-block"></div>
          <div class="diagram-circle-pouring diagram-circle-zabot-block"></div>
          <div class="diagram-circle-pouring diagram-circle-tvor-block"></div>
          <div class="diagram-circle-pouring diagram-circle-prav-block"></div>
          <div class="diagram-circle-pouring diagram-circle-mal-block"></div>
          <div class="diagram-circle-pouring diagram-circle-love-block"></div>
          <div class="diagram-circle-pouring diagram-circle-jester-block"></div>
          <div class="diagram-circle-numbers">
            <p>6</p>
            <p>34</p>
            <p>24</p>
            <p>96</p>
            <p>20</p>
            <p>64</p>
            <p>26</p>
            <p>7</p>
            <p>16</p>
            <p>42</p>
            <p>22</p>
            <p>13</p>
          </div>
        </div>
        <div class="main-mobile-point">
          <p class="main-mobile-point-p">!</p>
          <p class="main-text main-mobile-point-desc">
            Отвечайте на вопросы вдумчиво, чтобы получить реальный и достоверный результат.
          </p>
        </div>
        <p class="main-text main-mobile-text">
          Рекомендую проходить тест регулярно, чтобы наблюдать изменения персональных трендов. <br><br>

          Тест 12D - это диагностика личности, основанная на моей обширной научной и практической базе исследования архетипов.
        </p>
      </div>
      <div class="main-left">
        <div class="main-left-time">
          <img
              src="../assets/images/instructions-time.svg"
              alt="time"
          />
          <p class="main-text">
            В тесте 60 вопросов, проведите 10 приятных минут с чашечкой кофе, чтобы увидеть свои ведущие архетипы.
          </p>
        </div>
        <p class="main-text">
          Вопросы составлены в виде утверждений, на которые нужно выбрать подходящий балл от 0 до 10. <br><br>
          Балльная система дает более точно представление о расстановке сил в вашей системе личности.
        </p>
        <div class="diagram">
          <img
              src="../assets/images/instructions-diagram.svg"
              alt="diagram"
              class="diagram-circle"
          />
          <img src="../assets/images/diagram/nevin.svg" alt="diagram" class="diagram-circle-nevin"/>
          <img src="../assets/images/diagram/isk.svg" alt="diagram" class="diagram-circle-isk"/>
          <img src="../assets/images/diagram/intel.svg" alt="diagram" class="diagram-circle-intel"/>
          <img src="../assets/images/diagram/hero.svg" alt="diagram" class="diagram-circle-hero"/>
          <img src="../assets/images/diagram/izmen.svg" alt="diagram" class="diagram-circle-izmen"/>
          <img src="../assets/images/diagram/duh.svg" alt="diagram" class="diagram-circle-duh"/>
          <img src="../assets/images/diagram/zabot.svg" alt="diagram" class="diagram-circle-zabot"/>
          <img src="../assets/images/diagram/tvor.svg" alt="diagram" class="diagram-circle-tvor"/>
          <img src="../assets/images/diagram/prav.svg" alt="diagram" class="diagram-circle-prav"/>
          <img src="../assets/images/diagram/mal.svg" alt="diagram" class="diagram-circle-mal"/>
          <img src="../assets/images/diagram/love.svg" alt="diagram" class="diagram-circle-love"/>
          <img src="../assets/images/diagram/jester.svg" alt="diagram" class="diagram-circle-jester"/>
          <div class="diagram-circle-pouring diagram-circle-nevin-block"></div>
          <div class="diagram-circle-pouring diagram-circle-isk-block"></div>
          <div class="diagram-circle-pouring diagram-circle-intel-block"></div>
          <div class="diagram-circle-pouring diagram-circle-hero-block"></div>
          <div class="diagram-circle-pouring diagram-circle-izmen-block"></div>
          <div class="diagram-circle-pouring diagram-circle-duh-block"></div>
          <div class="diagram-circle-pouring diagram-circle-zabot-block"></div>
          <div class="diagram-circle-pouring diagram-circle-tvor-block"></div>
          <div class="diagram-circle-pouring diagram-circle-prav-block"></div>
          <div class="diagram-circle-pouring diagram-circle-mal-block"></div>
          <div class="diagram-circle-pouring diagram-circle-love-block"></div>
          <div class="diagram-circle-pouring diagram-circle-jester-block"></div>
          <div class="diagram-circle-numbers">
            <p>6</p>
            <p>34</p>
            <p>24</p>
            <p>96</p>
            <p>20</p>
            <p>64</p>
            <p>26</p>
            <p>7</p>
            <p>16</p>
            <p>42</p>
            <p>22</p>
            <p>13</p>
          </div>
        </div>
      </div>
      <div class="main-right">
        <img
          src="../assets/images/instructions-anna.webp"
          alt="anna"
          class="main-right-anna"
        />
        <img
          src="../assets/images/instructions-bar.svg"
          alt="bar"
          class="main-right-bar"
        />
        <div class="main-right-point">
          <p class="main-right-point-p">!</p>
          <p class="main-text main-right-text">
            Отвечайте на вопросы вдумчиво, чтобы получить реальный и достоверный результат.
          </p>
        </div>
        <p class="main-text main-right-text" style="margin-top: 34px;">
          Рекомендую проходить тест регулярно, чтобы наблюдать изменения персональных трендов. <br><br>

          Тест 12D - это диагностика личности, основанная на моей обширной научной и практической базе исследования архетипов.
        </p>
      </div>
    </div>
    <router-link to="/test" class="button instructions-button">
      Ок, начать тест!
    </router-link>
  </body>
</template>

<script>
import Cookies from "js-cookie";

export default {
  name: "InstructionsView",
  data() {
    return {
      session: Cookies.get("session")
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  beforeMount() {
    if (this.session === undefined) {
      return setSession(this);
    }
  }
}

function setSession(app) {
  return new Promise((resolve) => {
    app.axios.get('https://dev.annazavadskaya.ru:3000/api/user/session/get').then((res) => {
      Cookies.set('session', res.data, {expires: 365});
      app.session = res.data;
      resolve(res.data);
    });
  });
}

</script>

<style lang="scss" scoped>
@import "/src/assets/css/instructions.scss";
</style>